import Image from "next/image";
import { CountryCode } from '../../lib/constants';
import styles from '../style/services/OurService.module.css';
import { ElemenetType } from '../../lib/constants';
import { LeadPopUpButton } from '../shared/lead/LeadPopUpButton';
export function OurService(props: { countryCode: CountryCode}){
    
    return (
        <section className={`${styles.ourServices}`} >
            <div className="container">
                <div className={styles.trunkeyUae}>
                    <div className={styles.headingBx}>
                        <div>
                            <h2 className={`${styles.heading} font26 text000 f500 mb16 fontSM20 `}>Complete Turnkey Services</h2>
                            <p className={`${styles.subHeading} font16 text666 fontSM14`}>With an emphasis on details, extraordinary designs and exceptional customer service, we bring your dreams to life.</p>
                        </div>
                        <div>
                        <LeadPopUpButton ElemenetType={ElemenetType.span} ButtonClass={styles.turknyKnowMore} >
                        Know More <em><svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6 1 5 5-5 5M1 6h9.286H1z"  strokeLinecap="round" strokeLinejoin="round"/></svg></em>
                        </LeadPopUpButton>
                            {/* <div className={styles.turknyKnowMore}>
                                Know More <em><svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6 1 5 5-5 5M1 6h9.286H1z"  strokeLinecap="round" strokeLinejoin="round"/></svg></em>
                                </div> */}
                        </div>
                    </div>
                    <div className={styles.designBx}>
                        <figure className="mb16">
                            <Image src={'/assets/images/wedesign.svg'} width={140} height={122} alt="we design" className="imgFluid" />
                        </figure>
                        <label className={`font16 text000 f600 mb16 ${styles.designBxlabel}`} >We Design</label>
                        <p>From completed homes to modular kitchens, and storage to decor, our top interior designers create spaces that match your vision.</p>
                    </div>
                    <div className={styles.designBx}>
                    <figure className="mb16">
                            <Image src={'/assets/images/weExecute.svg'} width={126} height={122} alt="we execute" className="imgFluid" />
                        </figure>
                        <label className={`font16 text000 f600 mb16 ${styles.designBxlabel}`} >We Execute</label>
                        <p className={styles.designBxTxt}>We follow a meticulous planning approach with detail-driven designs for interiors of your homes.</p>
                    </div>
                    <div className={styles.designBx}>
                    <figure className="mb16">
                            <Image src={'/assets/images/weManage.svg'} width={89} height={122} alt="we manage" className="imgFluid" />
                        </figure>
                        <label className={`font16 text000 f600 mb16 ${styles.designBxlabel}`} >We Manage</label>
                        <p className={styles.designBxTxt}>Our top interior designers spearhead quality assurance by extending support after the execution of home projects.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}